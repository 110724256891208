<template>
    <div class="lens-card border-bottom mt-2">
        <b-row class="mb-2 text-center text-sm-left">
            <b-col sm="3" md="4" lg="4" class="pr-3 pr-xl-0">
                <b-row>
                    <b-col class="text-center">
                        <img class="mt-1" src="@/assets/shopping-cart/lens.svg" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <p
                            v-if="shouldShowPrimaryLensStatus(lens) && normalOrder"
                            class="mt-4"
                            :class="{
                                'font-weight-bold text-primary': lens.isPrimaryLens,
                            }"
                        >
                            {{
                                (lens.isPrimaryLens
                                    ? t(primaryLensStatusKeyText)
                                    : t(additionalLensStatusKeyText)) + ` - ${lens.opEye}`
                            }}
                        </p>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="4" md="4" lg="4" class="pl-3 pl-xl-0">
                <h5
                    class="mt-1 mb-1 font-weight-bold"
                    :class="{
                        'text-danger': checkRegulatoryItems(lens.shoppingCartItemId),
                    }"
                >
                    {{ lens.description }}
                </h5>
                <p v-if="checkRegulatoryItems(lens.shoppingCartItemId)" class="text-danger">
                    {{ t(regulatoryCheckNoteText) }}
                </p>
                <h6 v-if="canShowPrice && !canceledOrder" class="font-weight-bold">
                    {{ lens.price | toCurrency }}
                </h6>
                <h6>
                    <span v-if="normalOrder" :class="{'mr-2': lens.serial}">{{ lens.serial }}</span>
                    {{ lens.modelNumber }}
                </h6>
                <h6>{{ prescription }}</h6>
                <h6 v-if="lens.preOrderHeaderId && normalOrder">
                    {{ t('preordernumber') }}:
                    <custom-router-link
                        :to="{
                            name: 'PreOrderDetails',
                            params: {orderId: lens.preOrderHeaderId},
                        }"
                        class="text-primary font-weight-bold"
                    >
                        <u>{{ lens.preOrderHeaderId }}</u>
                    </custom-router-link>
                </h6>
                <h6 v-if="lens.patientId && normalOrder">
                    {{ t('checkout_Calculation') }}:
                    <span v-if="lens.dataSourceId === DATA_SOURCES.OCOS" class="mr-1 text-red">
                        <b-img :src="padLockIcon" height="18" width="18" class="mr-1 pb-1"></b-img>
                        OCOS
                    </span>
                    <custom-router-link
                        :to="{
                            name: 'PreOpData',
                            params: {
                                patientId: lens.patientId,
                                preOpDataSetId: lens.preOpDataSetId,
                            },
                        }"
                        :is="
                            lens.dataSourceId !== DATA_SOURCES.OCOS && canGoPreopData
                                ? 'custom-router-link'
                                : 'span'
                        "
                        :disabled="!canGoPreopData"
                        class="text-primary font-weight-bold"
                    >
                        <u>{{ lens.patientOcosId }} - {{ lens.opEye }}</u>
                    </custom-router-link>
                </h6>
                <h6 v-if="lens.doctorId && normalOrder">
                    {{ t('surgeon') }}:
                    <custom-router-link
                        :to="{
                            name: 'SurgeonDetails',
                            params: {doctorId: lens.doctorId},
                        }"
                        class="text-primary font-weight-bold"
                        :disabled="!hasSurgeonPermission"
                    >
                        <u>{{ lens.contactPartyNumber }} - {{ lens.doctorName }}</u>
                    </custom-router-link>
                </h6>
                <h6 v-if="lens.ocosLensOrderNumber">
                    {{ t('ordernumber') + ': ' }} {{ lens.ocosLensOrderNumber }}
                </h6>
            </b-col>
            <b-col sm="5" md="4" lg="4">
                <div class="float-sm-right text-center text-sm-left">
                    <div class="text-center">
                        <b-button
                            :class="{'hidden-element': readonly || !showAddButtom}"
                            @click="$emit('on-add', lens)"
                            variant="primary"
                            class="mt-1 mr-2"
                            v-if="showAddButtom"
                        >
                            {{ t(addButtomKeyText) }}
                        </b-button>
                        <b-button
                            v-if="!readonly && showRemoveButtom"
                            @click="showRemovePrompt = true"
                            variant="secondary"
                            class="mt-1"
                        >
                            {{ t(removeButtomKeyText) }}
                        </b-button>
                    </div>
                    <slot name="custom-actions-before" :props="$data" />
                    <h6
                        class="mt-2 font-weight-bold"
                        :class="{
                            'text-center':
                                (!showAddButtom && !showRemoveButtom) || readonly || isConsignment,
                        }"
                    >
                        {{ t(isConsignment ? 'consignment_NameCaps' : 'staarName') }}
                    </h6>
                    <slot name="custom-actions-after" :props="$data" />
                    <h6
                        class="float-right"
                        :class="{
                            ['hidden-element']: !showExpirationWindow,
                        }"
                    >
                        <span class="ml-3 text-danger" v-if="!readonly">
                            {{ lens.daysTillExpiration }} {{ t('checkout_DaysRemaining') }}
                        </span>
                    </h6>
                </div>
            </b-col>
            <LensPrompt
                :show.sync="showRemovePrompt"
                :lens="lens"
                :showExpirationWindow="showExpirationWindow"
                :removePromptKeyText="removePromptKeyText"
                :isConfirmButttonDisabled="isConfirmButttonDisabled"
                @on-remove="$emit('on-remove', $event)"
            >
                <template slot="badge">
                    <slot name="badge" :props="$data"></slot>
                </template>
                <template slot="remove-prompt-before-card">
                    <slot name="remove-prompt-before-card" :props="$data"></slot>
                </template>
                <template slot="remove-prompt-after-card">
                    <slot name="remove-prompt-after-card" :props="$data"></slot>
                </template>
            </LensPrompt>
        </b-row>
    </div>
</template>

<script>
import {decimalSeparatorFormatter} from '@/utilities/formatters';
import LensPrompt from './LensPrompt.vue';
import {InventorySource} from '@/constants/inventory';
import {DATA_SOURCES} from '@/constants/preopdata';
import padlock from '@/assets/padlock.svg';
import {OracleOrderType} from '@/constants/order';

export default {
    name: 'LensCard',
    components: {LensPrompt},
    props: {
        lens: {
            type: Object,
            default: () => {},
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        showExpirationWindow: {
            type: Boolean,
            default: true,
        },
        showAddButtom: {
            type: Boolean,
            default: true,
        },
        showRemoveButtom: {
            type: Boolean,
            default: true,
        },
        showPrimaryLensStatus: {
            type: Boolean,
            default: false,
        },
        isConfirmButttonDisabled: {
            type: Boolean,
            default: false,
        },
        addButtomKeyText: {
            type: String,
            default: 'checkout_AddLens',
        },
        removeButtomKeyText: {
            type: String,
            default: 'checkout_Remove',
        },
        removePromptKeyText: {
            type: String,
            default: 'checkout_RemoveLens',
        },
        primaryLensStatusKeyText: {
            type: String,
            default: 'checkout_PrimaryLensStatus',
        },
        additionalLensStatusKeyText: {
            type: String,
            required: true,
        },
        failedRegulatoryCheckItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        regulatoryCheckNoteText: {
            type: String,
            default: 'regulatoryCheckNoteText',
        },
        orderType: {
            type: Number,
            default: OracleOrderType.NORMAL,
        },
    },
    data() {
        return {
            showRemovePrompt: false,
            DATA_SOURCES,
            padLockIcon: padlock,
        };
    },
    computed: {
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [this.PERMISSIONS.DISTRIBUTOR_MODE]: [this.PERMISSIONS_VALUES.SURGEON],
            });
        },
        isDistributorMode() {
            return this.checkPermissions({
                [this.PERMISSIONS.DISTRIBUTOR_MODE]: [this.PERMISSIONS_VALUES.DISTRIBUTOR],
            });
        },
        hasPricingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.PRICING]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
        hasSurgeonPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.SURGEONS]: [
                    this.PERMISSIONS_VALUES.READ_ONLY,
                    this.PERMISSIONS_VALUES.READ_WRITE,
                    this.PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                ],
            });
        },
        isConsignment() {
            return this.lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
        prescription() {
            return decimalSeparatorFormatter(
                this.lens.prescription,
                this.currentUser.decimalSeparator
            );
        },
        canGoPreopData() {
            return (
                !this.isDistributorMode &&
                this.checkPermissions({
                    [this.PERMISSIONS.PATIENT_PREOP_DATA]: [
                        this.PERMISSIONS_VALUES.READ_WRITE,
                        this.PERMISSIONS_VALUES.READ_ONLY,
                    ],
                })
            );
        },
        canShowPrice() {
            return (
                this.hasPricingPermission && !this.isDistributorSurgeonMode && !this.isConsignment
            );
        },
        normalOrder() {
            return this.orderType == OracleOrderType.NORMAL;
        },
        canceledOrder() {
            return this.orderType == OracleOrderType.CANCELED;
        },
        manualOrder() {
            return this.orderType == OracleOrderType.MANUAL;
        },
    },
    methods: {
        shouldShowPrimaryLensStatus(lens) {
            return this.showPrimaryLensStatus && lens.dataSourceId !== DATA_SOURCES.OCOS;
        },
        checkRegulatoryItems(itemId) {
            return this.failedRegulatoryCheckItems?.includes(itemId) ?? false;
        },
    },
};
</script>
<style lang="scss" scoped></style>
